import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useSonographyReportPhotoService = () => {
  const axiosAuth = useAxiosAuth();

  const addSonographyReportPhoto = async (id, fd) => {
    console.log("id1", id, fd);
    try {
      const response = await axiosAuth.post(
        `/api/SonographyPhotos/upload-sonography-photo?sonographyId=${id}`,
        fd
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getSonographyPhotoPatientById = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/SonographyPhotos?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getSonographyPhotoByScanReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(
        `/api/SonographyPhotos/by-sonography-id?sonographyId=${reportId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteSonographyPhoto = async (id) => {
    try {
      const response = await axiosAuth.delete(`/api/sonographyPhotos?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addSonographyReportPhoto,
    getSonographyPhotoPatientById,
    getSonographyPhotoByScanReportId,
    deleteSonographyPhoto,
  };
};
export default useSonographyReportPhotoService;
