// assets
import { IconTestPipe, IconPlus } from "@tabler/icons";

// constant
const icons = {
  IconTestPipe,
  IconPlus,
};

// ==============================|| TECHNICIAN MENU ITEMS ||============================== //

const technicianMenuItems = [
  {
    id: "lab-main-pages",
    type: "group",
    children: [
      {
        id: "investigations",
        title: "Investigations",
        type: "item",
        icon: icons.IconTestPipe,
        url: "/investigations",
        breadcrumbs: false,
      },
      // {
      //   id: "additional-treatment",
      //   title: "Additional Treatmant",
      //   type: "item",
      //   url: "/additional-treatment",
      //   icon: icons.IconPlus,
      //   breadcrumbs: false,
      // },
    ],
  },
];

export default technicianMenuItems;
