import React, { useContext } from "react";
import LetterHead from "ui-component/LetterHead";
import ReportContent from "./ReportContent";
import PrintReportContext from "./ReportContext";
import { Typography, Stack } from "@mui/material";
import ReportCardData from "./ReportCardData";
const PrintReport = React.forwardRef((props, ref) => {
  const { reportdata, reportName, reportId, doctorsdata, patientname, theme } =
    useContext(PrintReportContext);
  const getPageMargins = () => {
    return `@page { margin: ${"40px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  const pageStyle = "@page {size: A4 portrait;}";
  //actual data print here
  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <div style={{ position: "fixed", top: "0", width: "100%" }}>
          <LetterHead />
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <div style={{ height: "100px" }}></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <Typography variant="h4" align="center" sx={{ mb: 2 }}>
                    {reportName} Report
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 2 }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography variant="subtitle1">
                        Patient Name:{" "}
                      </Typography>
                      <Typography>
                        {patientname?.patientMaster.salutationDisplay}{" "}
                        {patientname?.patientMaster.firstName}{" "}
                        {patientname?.patientMaster.middleName}{" "}
                        {patientname?.patientMaster.lastName}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography variant="subtitle1">Date: </Typography>
                      <Typography>
                        {new Date(reportdata?.date).toLocaleDateString("hi-IN")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <ReportCardData id={0} />
                  <br />
                  <br />
                  <Typography fontSize={16} textAlign="right">
                    Thank you!
                  </Typography>
                  <br />
                  <br />
                  <br />
                  <Typography variant="h4" textAlign="right">
                    Authorized Signature
                  </Typography>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
});

export default PrintReport;
