import axios from "axios";
//export const BASE_URL = "https://localhost:7046"; //localhost

//export const BASE_URL = "https://sushilivfapi.drcodez.com"; //production server
export const BASE_URL = "https://sushilapi.drcodez.com"; //test server

// export const BASE_URL = "http://192.168.1.12:5052"; //localhost- Sneha Machine URL

export const axiosOpen = axios.create({
  baseURL: BASE_URL,
});

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  headers: { ContentType: "application/json" },
  withCredentials: true,
});
