import { useRoutes } from "react-router-dom";
import { useReducer, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import MinimalLayout from "layout/MinimalLayout";
import Loadable from "ui-component/Loadable";
import config from "config";
import InvestigationDetails from "views/investigations/InvestigationDetails";
import AdditionalTreatmentDetails from "views/additional-treatment/AdditionalTretmentDetails";
// login option 3 routing
const Login = Loadable(lazy(() => import("views/login")));
const SignUp = Loadable(lazy(() => import("views/signup/index")));
const ForgotPassword = Loadable(
  lazy(() => import("views/login/forgot-password"))
);

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// patient page routing
const PatientList = Loadable(lazy(() => import("views/patients/patient-list")));
const FilteredPatientList = Loadable(
  lazy(() => import("views/patients/filtered-patient-list"))
);
const PatientForm = Loadable(lazy(() => import("views/patients/patient-form")));
const PatientDetails = Loadable(
  lazy(() => import("views/patients/patient-details"))
);
const BillList = Loadable(lazy(() => import("views/billing/bill-list")));
const BillForm = Loadable(lazy(() => import("views/billing/bill-form")));
const DischargeCards = Loadable(
  lazy(() => import("views/discharge-cards/discharge-card-list"))
);
const DischargeCardForm = Loadable(
  lazy(() => import("views/discharge-cards/discharge-card-form"))
);
const OpdReceipts = Loadable(
  lazy(() => import("views/billing/opd-receipt-list"))
);
const AdvancePayment = Loadable(
  lazy(() => import("views/billing/advance-payment"))
);

//!Accounts Investigations
const Investigations = Loadable(lazy(() => import("views/investigations")));

// appointment page routing
const Appointments = Loadable(
  lazy(() => import("views/appointments/appointments"))
);
const AppointmentForm = Loadable(
  lazy(() => import("views/appointments/appointment-form"))
);
const IPDOverview = Loadable(lazy(() => import("views/ipd/ipd-overview")));
const MyAppointments = Loadable(
  lazy(() => import("views/appointments/my-appointments"))
);
const PrescriptionList = Loadable(
  lazy(() => import("views/prescription-list"))
);
const BillItems = Loadable(lazy(() => import("views/settings/bill-items")));
const Users = Loadable(lazy(() => import("views/settings/users")));
const ExternalStaff = Loadable(
  lazy(() => import("views/settings/external-staff"))
);
const InvestigationItems = Loadable(
  lazy(() => import("views/settings/investigation-items"))
);
const OpdReceiptItemMaster = Loadable(
  lazy(() => import("views/settings/opd-receipt-items"))
);
const OpdReceiptForm = Loadable(
  lazy(() => import("views/billing/opd-receipt-form"))
);
//appointment type
const GenralSettings = Loadable(
  lazy(() => import("views/settings/general-settings"))
);
const OperationTypes = Loadable(
  lazy(() => import("views/settings/operation-types/index"))
);

const MLCForm = Loadable(lazy(() => import("views/appointments/mlc-form")));
const DrugItems = Loadable(lazy(() => import("views/settings/drug-items")));
const PrescriptionTemplates = Loadable(
  lazy(() => import("views/settings/prescription-templates"))
);

//Certificate page routing

const Certificates = Loadable(lazy(() => import("views/certificates/index")));

//Report page routing

const InvestigationReports = Loadable(
  lazy(() => import("views/reports/InvestigationReports"))
);
const IpdReports = Loadable(
  lazy(() => import("views/reports/IPDBillingReports"))
);
const OpdReports = Loadable(
  lazy(() => import("views/reports/OPDBillingReports"))
);

//external reports
//growth ultrasound scan report
const GrowthUltraSoundReports = Loadable(
  lazy(() =>
    import(
      "views/patients/patient-details/external-reports/GrowthUltrsoundScan/GrowthUltraSoundReportForm"
    )
  )
);
//sonography report
const SonoGraphyReport = Loadable(
  lazy(() =>
    import("views/patients/patient-details/external-reports/sonography-report")
  )
);

const VaibilityReport = Loadable(
  lazy(() =>
    import("views/patients/patient-details/external-reports/viablityReport")
  )
);
//Print Report
const PrintReport = Loadable(
  lazy(() =>
    import("views/patients/patient-details/external-reports/report-print")
  )
);
const PrintDoctorsReport = Loadable(
  lazy(() =>
    import("views/patients/patient-details/doctores-reports/report-print")
  )
);

//Doctor's Report

const LaproScopyReport = Loadable(
  lazy(() =>
    import("views/patients/patient-details/doctores-reports/LaproScopyReport")
  )
);
const HysterolaproscopyReport = Loadable(
  lazy(() =>
    import(
      "views/patients/patient-details/doctores-reports/HysterolaproscopyReport"
    )
  )
);
const LummellaReport = Loadable(
  lazy(() =>
    import("views/patients/patient-details/doctores-reports/LummelaReport")
  )
);

//Pharmacy Management
const PharmacyManagement = Loadable(
  lazy(() => import("views/pharmacy-management/index"))
);

// //Pharmacy Management
// const MyAllAppointments = Loadable(
//   lazy(() => import("views/patient-login-data/index"))
// );
//Pardriatic Pages

//Patient Only Pages
const AppointmentHistory = Loadable(
  lazy(() => import("views/patient-only-data/appointment-history/index"))
);
const AppointmentBooking = Loadable(
  lazy(() => import("views/patient-only-data/appointment-booking/index"))
);
const BasicProfile = Loadable(
  lazy(() => import("views/patient-only-data/basic-profile/index"))
);

//Appointment Slot Master
const AppointmentSlot = Loadable(
  lazy(() => import("views/settings/appointment-slots/index"))
);

//Referred By Master
const ReferredBy = Loadable(
  lazy(() => import("views/settings/referredBy/index"))
);
//Additional Treatment Master
const AdditionalTreatmentMaster = Loadable(
  lazy(() => import("views/settings/additional-treatment/index"))
);

//expense Category
const ExpenseCategory = Loadable(
  lazy(() => import("views/settings/expense-category"))
);
const OperatingExpenses = Loadable(
  lazy(() => import("views/operating-expenses"))
);

const AdditionalTreatment = Loadable(
  lazy(() => import("views/additional-treatment"))
);

//Day Care Overview
const DayCareOverview = Loadable(lazy(() => import("views/day-care/index")));

//Advice Item Master
const AdviceItemMaster = Loadable(
  lazy(() => import("views/settings/advice-items/index"))
);

//Clinical Finding Item Master
const ClinicalFindingMaster = Loadable(
  lazy(() => import("views/settings/clinical-finding-items/index"))
);

//Revenue Report
const RevenueReport = Loadable(lazy(() => import("views/revenueReport/index")));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  const AuthenticationRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
    ],
  };

  const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: userId > 0 ? <PatientList /> : <Navigate to="/login" />,
      },
      {
        path: "/dashboard",
        element: userId > 0 ? <DashboardDefault /> : <Navigate to="/login" />,
      },
      {
        path: "/patients",
        element: userId > 0 ? <PatientList /> : <Navigate to="/login" />,
      },
      {
        path: "/filtered-patients",
        element:
          userId > 0 ? <FilteredPatientList /> : <Navigate to="/login" />,
      },
      {
        path: "/patient-form",

        element: userId > 0 ? <PatientForm /> : <Navigate to="/login" />,
      },

      {
        path: "/growthultrasound-form",
        element:
          userId > 0 ? <GrowthUltraSoundReports /> : <Navigate to="/login" />,
      },
      {
        path: "/sonography-form",
        element: userId > 0 ? <SonoGraphyReport /> : <Navigate to="/login" />,
      },
      {
        path: "/viability-form",
        element: userId > 0 ? <VaibilityReport /> : <Navigate to="/login" />,
      },
      {
        path: "/print-report",
        element: userId > 0 ? <PrintReport /> : <Navigate to="/login" />,
      },
      {
        path: "/print-doctorsreport",

        element: userId > 0 ? <PrintDoctorsReport /> : <Navigate to="/login" />,
      },

      {
        path: "/laproscopy-form",
        element: userId > 0 ? <LaproScopyReport /> : <Navigate to="/login" />,
      },
      {
        path: "/hysterolaproscopy-form",
        element:
          userId > 0 ? <HysterolaproscopyReport /> : <Navigate to="/login" />,
      },
      {
        path: "/lummella-form",
        element: userId > 0 ? <LummellaReport /> : <Navigate to="/login" />,
      },

      {
        path: "/patient-details",
        element: userId > 0 ? <PatientDetails /> : <Navigate to="/login" />,
      },
      {
        path: "/billing",
        element: userId > 0 ? <BillList /> : <Navigate to="/login" />,
      },
      {
        path: "/prescription-list",
        element: userId > 0 ? <PrescriptionList /> : <Navigate to="/login" />,
      },
      {
        path: "/bill-form",
        element: userId > 0 ? <BillForm /> : <Navigate to="/login" />,
      },
      {
        path: "/advance-payments",
        element: userId > 0 ? <AdvancePayment /> : <Navigate to="/login" />,
      },
      {
        path: "/opd-receipts",
        element: userId > 0 ? <OpdReceipts /> : <Navigate to="/login" />,
      },
      {
        path: "/opd-receipts-form",
        element: userId > 0 ? <OpdReceiptForm /> : <Navigate to="/login" />,
      },
      {
        path: "/discharge-cards",
        element: userId > 0 ? <DischargeCards /> : <Navigate to="/login" />,
      },
      {
        path: "/discharge-card-form",
        element: userId > 0 ? <DischargeCardForm /> : <Navigate to="/login" />,
      },
      {
        path: "/appointments",
        element: userId > 0 ? <Appointments /> : <Navigate to="/login" />,
      },
      {
        path: "/appointment-form",
        element: userId > 0 ? <AppointmentForm /> : <Navigate to="/login" />,
      },
      {
        path: "/my-appointments",
        element: userId > 0 ? <MyAppointments /> : <Navigate to="/login" />,
      },

      {
        path: "/ipd-overview",
        element: userId > 0 ? <IPDOverview /> : <Navigate to="/login" />,
      },
      {
        path: "/bill-items",
        element: userId > 0 ? <BillItems /> : <Navigate to="/login" />,
      },
      {
        path: "/opd-receipt-item-master",
        element:
          userId > 0 ? <OpdReceiptItemMaster /> : <Navigate to="/login" />,
      },
      {
        path: "/drug-items",
        element: userId > 0 ? <DrugItems /> : <Navigate to="/login" />,
      },
      {
        path: "/employees",
        element: userId > 0 ? <Users /> : <Navigate to="/login" />,
      },
      {
        path: "/external-staff",
        element: userId > 0 ? <ExternalStaff /> : <Navigate to="/login" />,
      },

      {
        path: "/ipd-overview",
        element: userId > 0 ? <IPDOverview /> : <Navigate to="/login" />,
      },
      {
        path: "/mlc-form",
        element: userId > 0 ? <MLCForm /> : <Navigate to="/login" />,
      },
      {
        path: "/certificates",
        element: userId > 0 ? <Certificates /> : <Navigate to="/login" />,
      },
      {
        path: "/prescription-templates",
        element:
          userId > 0 ? <PrescriptionTemplates /> : <Navigate to="/login" />,
      },
      {
        path: "/investigation-items",
        element: userId > 0 ? <InvestigationItems /> : <Navigate to="/login" />,
      },
      {
        path: "/investigations",
        element: userId > 0 ? <Investigations /> : <Navigate to="/login" />,
      },
      {
        path: "/investigation-details",
        element:
          userId > 0 ? <InvestigationDetails /> : <Navigate to="/login" />,
      },
      {
        path: "/operation-types",
        element: userId > 0 ? <OperationTypes /> : <Navigate to="/login" />,
      },
      {
        path: "/investigation-reports",
        element:
          userId > 0 ? <InvestigationReports /> : <Navigate to="/login" />,
      },
      {
        path: "/opd-reports",
        element: userId > 0 ? <OpdReports /> : <Navigate to="/login" />,
      },
      {
        path: "/ipd-reports",
        element: userId > 0 ? <IpdReports /> : <Navigate to="/login" />,
      },
      {
        path: "/pharmacy-management",
        element: userId > 0 ? <PharmacyManagement /> : <Navigate to="/login" />,
      },

      {
        path: "/appointment-history",
        element: userId > 0 ? <AppointmentHistory /> : <Navigate to="/login" />,
      },
      {
        path: "/appointment-booking",
        element: userId > 0 ? <AppointmentBooking /> : <Navigate to="/login" />,
      },
      {
        path: "/basic-profile",
        element: userId > 0 ? <BasicProfile /> : <Navigate to="/login" />,
      },
      {
        path: "/appointment-slot",
        element: userId > 0 ? <AppointmentSlot /> : <Navigate to="/login" />,
      },
      {
        path: "/refferedBy",
        element: userId > 0 ? <ReferredBy /> : <Navigate to="/login" />,
      },
      {
        path: "/settings/additional-treatment",
        element:
          userId > 0 ? <AdditionalTreatmentMaster /> : <Navigate to="/login" />,
      },
      {
        path: "/expense-category-item-master",
        element: userId > 0 ? <ExpenseCategory /> : <Navigate to="/login" />,
      },
      {
        path: "/operating-expenses",
        element: userId > 0 ? <OperatingExpenses /> : <Navigate to="login" />,
      },
      {
        path: "/general-settings",
        element: userId > 0 ? <GenralSettings /> : <Navigate to="login" />,
      },
      {
        path: "/additional-treatment",
        element: userId > 0 ? <AdditionalTreatment /> : <Navigate to="login" />,
      },
      {
        path: "/additionalTreatment-details",
        element:
          userId > 0 ? (
            <AdditionalTreatmentDetails />
          ) : (
            <Navigate to="/login" />
          ),
      },
      {
        path: "/daycare-overview",
        element: userId > 0 ? <DayCareOverview /> : <Navigate to="/login" />,
      },
      {
        path: "/advice-items",
        element: userId > 0 ? <AdviceItemMaster /> : <Navigate to="/login" />,
      },
      {
        path: "/clinicalFinding-items",
        element:
          userId > 0 ? <ClinicalFindingMaster /> : <Navigate to="/login" />,
      },
      {
        path: "/revenue-report",
        element: userId > 0 ? <RevenueReport /> : <Navigate to="/login" />,
      },
    ],
  };

  return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
