import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  Snackbar,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
import useGrowthAltraSoundScanService from "services/useGrowthAltrasoundScanService";
import useGrowthUltraSoundScanPhotoService from "services/useGrowthultrasoundReportPhotoServices";
import AlertMessage from "ui-component/AlertMessage";
import useSonographyReportPhotoService from "services/useSonographyReportPhoto";
import useSonographyService from "services/useSonographyReport";
import useViabilityReportPhotoService from "services/useViabilityReportPhotos";
import useViabilityReportService from "services/useViabilityReport";
import useLaporoscopyServices from "services/useLaporoscopyServices";
import useLaproscopyPhotosServices from "services/useLaproscopyPhotosServices";
import useHysterolaproscopyPhotosServices from "services/useHysterolaproscopyPhotosServices";
import useHysterolaproscopyServices from "services/useHysterolaproscopyServices";
import useLummellaPhotosServices from "services/useLummellaPhotosServices";
import useLummellaServices from "services/useLummellaServices";
const DeleteDialog = ({
  openDelete,
  handleCancelDelete,
  selectedReport,
  reportName,
  loadAllReportData,
}) => {
  const theme = useTheme();
  //call services
  const { deleteLaproscopyRecord } = useLaporoscopyServices();
  const { deleteLaproscopyPhoto } = useLaproscopyPhotosServices();
  const { deleteHysterolaproscopyPhoto } = useHysterolaproscopyPhotosServices();
  const { deleteHysterolaproscopyRecord } = useHysterolaproscopyServices();
  const { deleteLummellaPhoto } = useLummellaPhotosServices();
  const { deleteLummellaRecord } = useLummellaServices();
  const [opendeleteTost, setOpenDeleteTost] = useState(false);
  useEffect(() => {
    if (opendeleteTost) {
      setTimeout(() => {
        setOpenDeleteTost(false);
      }, 1000);
    }
  }, [opendeleteTost]);
  const handleDelete = () => {
    console.log("reportName", reportName, selectedReport.id);
    if (reportName.toLowerCase() == "laproscopy") {
      deleteLaproscopyRecord(selectedReport.id)
        .then((result) => {
          console.log(result);
          loadAllReportData();
          handleCancelDelete();
          setOpenDeleteTost(true);
        })
        .catch((err) => {
          console.log("errrrrr", err);
        });

      deleteLaproscopyPhoto(selectedReport.id)
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    } else if (
      reportName.toLowerCase().replace(" ", "") == "hysterolaproscopy"
    ) {
      deleteHysterolaproscopyRecord(selectedReport.id)
        .then((result) => {
          console.log(result);
          loadAllReportData();
          handleCancelDelete();
          setOpenDeleteTost(true);
        })
        .catch((err) => {
          console.log(err);
        });
      deleteHysterolaproscopyPhoto(selectedReport.id)
        .then((result) => {})

        .catch((err) => console.log(err));
    } else if (reportName.toLowerCase() == "lummella") {
      deleteLummellaRecord(selectedReport.id).then((result) => {
        loadAllReportData();
        handleCancelDelete();
        setOpenDeleteTost(true);
      });
      deleteLummellaPhoto(selectedReport.id)
        .then((result) => {})
        .catch((err) => console.log(err));
    } else {
      console.log("yhghhgh");
    }
  };

  return (
    <>
      <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Report for
            <span style={{ color: "#E90000" }}>{` ${reportName} report`}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <AlertMessage
        message={"Report deleted succesfully!!"}
        opendeleteTost={opendeleteTost}
      />
    </>
  );
};

export default DeleteDialog;
