import React, { useContext } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import PrintReportContext from "./ReportContext";
const ReportSingleComponent = ({ label, data, id }) => {
  const { isSmallScreen, theme } = useContext(PrintReportContext);
  return (
    <>
      <Grid item sm={id == 0 ? 4 : isSmallScreen ? 4 : 2}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            paddingLeft: 1,
            paddingTop: 0.5,
            borderLeft: "solid",
            borderWidth: 3,
            borderColor: theme.palette.grey[400],
          }}
        >
          <Typography variant="h5" color={theme.palette.grey[700]}>
            {label}:
          </Typography>
          <Typography>{data}</Typography>
        </Stack>
      </Grid>
    </>
  );
};

export default ReportSingleComponent;
